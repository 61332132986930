"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TransactionAdvanceSearchComponent = /** @class */ (function () {
    function TransactionAdvanceSearchComponent() {
    }
    TransactionAdvanceSearchComponent.prototype.addNewElement = function () {
        this.searchGroup.elements.push(this.newElement());
    };
    TransactionAdvanceSearchComponent.prototype.addNewGroup = function () {
        this.searchGroup.groups.unshift(this.newGroup());
    };
    TransactionAdvanceSearchComponent.prototype.remove = function (arr, element) {
        var index = arr.indexOf(element, 0);
        if (index > -1) {
            arr.splice(index, 1);
        }
    };
    TransactionAdvanceSearchComponent.prototype.removeElement = function (element) {
        this.remove(this.searchGroup.elements, element);
    };
    TransactionAdvanceSearchComponent.prototype.removeGroup = function (group) {
        this.remove(this.searchGroup.groups, group);
    };
    TransactionAdvanceSearchComponent.prototype.newGroup = function () {
        return {
            elements: [
                this.newElement(),
                this.newElement()
            ],
            groups: [],
            logic: this.logicOperatorOptions[0].value
        };
    };
    TransactionAdvanceSearchComponent.prototype.newElement = function () {
        return {
            columnInfo: this.advanceSearchableColumns[0],
            matchModelOption: this.filterMatchModeOptions[0].value,
            value: "",
            logic: this.logicOperatorOptions[0].value
        };
    };
    TransactionAdvanceSearchComponent.prototype.getFilterMatchModeOptions = function (type) {
        return this.filterMatchModeOptions.filter(function (o) { return o.supportDataTypes.some(function (f) { return f === type; }); });
    };
    TransactionAdvanceSearchComponent.prototype.onAdd = function (value, element) {
        this.remove(element.values, value);
        value.split(",")
            .map(function (item) { return item.trim(); })
            .filter(Boolean)
            .map(function (item) {
            var index = element.values.indexOf(item, 0);
            if (index > -1) {
                return;
            }
            element.values.push(item.trim());
        });
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], TransactionAdvanceSearchComponent.prototype, "searchGroup", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Array)
    ], TransactionAdvanceSearchComponent.prototype, "filterMatchModeOptions", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Array)
    ], TransactionAdvanceSearchComponent.prototype, "logicOperatorOptions", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Array)
    ], TransactionAdvanceSearchComponent.prototype, "dynamicDateFilterMatchModelOption", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Array)
    ], TransactionAdvanceSearchComponent.prototype, "advanceSearchableColumns", void 0);
    TransactionAdvanceSearchComponent = __decorate([
        core_1.Component({
            selector: "transaction-advance-search",
            template: require('./transaction-advance-search.component.html')
        })
    ], TransactionAdvanceSearchComponent);
    return TransactionAdvanceSearchComponent;
}());
exports.TransactionAdvanceSearchComponent = TransactionAdvanceSearchComponent;
