"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var services_1 = require("../../services");
var bases_1 = require("../bases");
var RegionManagementComponent = /** @class */ (function (_super) {
    __extends(RegionManagementComponent, _super);
    function RegionManagementComponent(regionService, notificationsService, route) {
        var _this = _super.call(this, notificationsService, route) || this;
        _this.regionService = regionService;
        _this.cols = _this.gridLayoutInfo;
        return _this;
    }
    RegionManagementComponent.prototype.ngOnInit = function () {
        this.notificationsService.info("Component", "User Management Component is loading...");
        this.region = {};
        this.regions = [];
    };
    ;
    //#region Grid Control
    RegionManagementComponent.prototype.filter = function () {
        return { filter: this.lazyLoadEvent, cols: this.cols };
    };
    RegionManagementComponent.prototype.lazyLoad = function (event) {
        var _this = this;
        this.lazyLoadEvent = event;
        this.loading = true;
        this.regionService.getList(this.filter())
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.totalRecords = result.totalCount;
            _this.regions = result.result;
            if (_this.refreshing) {
                _this.notificationsService.successMsg("Refreshed successfully!");
                _this.refreshing = false;
            }
            _this.loading = false;
        }, function (error) {
            _this.regions = [];
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
        });
    };
    //#endregion
    //#region Region CRUD
    RegionManagementComponent.prototype.new = function () {
        this.newRegion = true;
        this.region = {};
        this.dialogVisible = true;
    };
    RegionManagementComponent.prototype.edit = function () {
        this.newRegion = false;
        this.region = this.clone(this.selectedRegion);
        this.dialogVisible = true;
    };
    RegionManagementComponent.prototype.delete = function () {
        var _this = this;
        this.dialogVisible = false;
        this.regionService.delete(this.selectedRegion.id).subscribe(function () {
            _this.dialogVisible = false;
            _this.notificationsService.successMsg("Region is deleted successfully!");
            _this.lazyLoad(_this.lazyLoadEvent);
        }, function (error) {
            _this.notificationsService.error("Failed to delete selected region!");
            console.log(error);
        });
    };
    RegionManagementComponent.prototype.save = function () {
        var _this = this;
        this.regionService.save(this.region).subscribe(function (region) {
            _this.dialogVisible = false;
            _this.lazyLoad(_this.lazyLoadEvent);
            if (_this.newRegion) {
                _this.notificationsService.successMsg("Region is created successfully!");
                return;
            }
            _this.notificationsService.successMsg("Region is modified successfully!");
            _this.selectedRegion = _this.clone(region);
        }, function (error) {
            _this.notificationsService.error("Failed to add/modify selected region!");
            console.log(error);
        });
    };
    RegionManagementComponent.prototype.close = function () {
        var _this = this;
        this.dialogVisible = false;
        setTimeout(function () { _this.newRegion = false; }, 1000);
    };
    RegionManagementComponent.prototype.clone = function (c) {
        var region = {};
        for (var prop in c) {
            if (c.hasOwnProperty(prop)) {
                region[prop] = c[prop];
            }
        }
        return region;
    };
    //#endregion
    RegionManagementComponent.prototype.onShow = function (filter) {
        var _this = this;
        this.regionService.get(this.region.id, filter).subscribe(function (result) {
            _this.region = result;
        }, function (error) {
            _this.regions = [];
            _this.notificationsService.error(error);
            console.log(error);
        });
    };
    RegionManagementComponent.prototype.dialogHeader = function () {
        return this.newRegion ? "New Region" : "Region Detail";
    };
    RegionManagementComponent.prototype.validate = function (f) {
        return f.invalid;
    };
    RegionManagementComponent = __decorate([
        core_1.Component({
            template: require('./regionmanagement.component.html'),
            styles: [
                "\n        :host ::ng-deep p-button, .ui-button{float:left;margin-right:0.5em;}\n        :host ::ng-deep .ui-table-caption{background:none;border:none;min-height:3.0em;}\n        :host ::ng-deep .ui-paginator-left-content{color:black;font-size:.75em;padding-left:0.75em;padding-top:0.75em;}\n        :host ::ng-deep .ui-picklist.ui-picklist-responsive .ui-picklist-buttons{width:20px;}\n        "
            ]
        }),
        __metadata("design:paramtypes", [services_1.RegionService,
            services_1.NotificationsService,
            router_1.ActivatedRoute])
    ], RegionManagementComponent);
    return RegionManagementComponent;
}(bases_1.BasePrimeNgGridComponent));
exports.RegionManagementComponent = RegionManagementComponent;
