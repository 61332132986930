"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Global = /** @class */ (function () {
    function Global() {
    }
    Global.getEpoch = function (date, option) {
        switch (option) {
            case "ceil":
                return Math.ceil(new Date(date).getTime() / 1000);
            case "floor":
                return Math.floor(new Date(date).getTime() / 1000);
            default:
                return new Date(date).getTime() / 1000;
        }
    };
    Global.baseUserEndpoint = "api/userapi/";
    Global.userEndpoint = "api/user/";
    Global.userGetListEndpoint = "api/user/list";
    Global.userGetCompaniesEndpoint = "api/user/companies/";
    Global.userCountEndpoint = "api/user/count";
    Global.userValidationEndpoint = "api/login/validateUsername";
    Global.userLogoutEndpoint = "api/logout";
    Global.advanceSearchReportEndpoint = "api/advance-search/report";
    Global.advanceSearchEndpoint = "/api/advance-search";
    Global.advanceSearchAlertEndpoint = "/api/advance-search/alert";
    Global.companyEndpoint = "api/company";
    Global.regionEndpoint = "api/region";
    Global.mapReportEndpoint = "api/map";
    Global.currentUserLocalStorageItemName = "currentUser";
    return Global;
}());
exports.Global = Global;
