"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TransactionAlertComponent = /** @class */ (function () {
    function TransactionAlertComponent() {
    }
    TransactionAlertComponent.prototype.addNewAlert = function () {
        if (this.alerts == null) {
            this.alerts = [this.newAlert()];
            return;
        }
        this.alerts.push(this.newAlert());
    };
    TransactionAlertComponent.prototype.remove = function (arr, element) {
        var index = arr.indexOf(element, 0);
        if (index > -1) {
            arr.splice(index, 1);
        }
    };
    TransactionAlertComponent.prototype.removeAlert = function (alert) {
        this.remove(this.alerts, alert);
    };
    TransactionAlertComponent.prototype.newAlert = function () {
        return {
            logic: this.logicOperatorOptions[0].value,
            value: 0
        };
    };
    TransactionAlertComponent.prototype.isPercentageOption = function (value) {
        if (value == null) {
            return false;
        }
        return value.toLowerCase().indexOf("percentage") > 0;
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Array)
    ], TransactionAlertComponent.prototype, "alerts", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Array)
    ], TransactionAlertComponent.prototype, "logicOperatorOptions", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Array)
    ], TransactionAlertComponent.prototype, "alertType", void 0);
    TransactionAlertComponent = __decorate([
        core_1.Component({
            selector: "transaction-alert",
            template: require('./transaction-alert.component.html')
        })
    ], TransactionAlertComponent);
    return TransactionAlertComponent;
}());
exports.TransactionAlertComponent = TransactionAlertComponent;
