"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../../services");
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(mapReportService, notificationsService) {
        this.mapReportService = mapReportService;
        this.notificationsService = notificationsService;
        this.loading = false;
        this.loading = true;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mapReportService.getDashboardMap()
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.mapReports = result;
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
            return;
        }, function () { return _this.loading = false; });
    };
    DashboardComponent = __decorate([
        core_1.Component({ template: require('./dashboard.component.html') }),
        __metadata("design:paramtypes", [services_1.MapReportService,
            services_1.NotificationsService])
    ], DashboardComponent);
    return DashboardComponent;
}());
exports.DashboardComponent = DashboardComponent;
