"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var shared_1 = require("../shared");
var server_service_1 = require("./server.service");
var AdvanceSearchService = /** @class */ (function () {
    function AdvanceSearchService(serverService) {
        this.serverService = serverService;
    }
    AdvanceSearchService.prototype.advanceSearch = function (advanceSearch) {
        return this.serverService.post("" + shared_1.Global.advanceSearchReportEndpoint, advanceSearch);
    };
    AdvanceSearchService.prototype.advanceSearchDetail = function (advanceSearch, selected) {
        return this.serverService.post(shared_1.Global.advanceSearchReportEndpoint + "/" + encodeURIComponent(selected) + "/selected", advanceSearch);
    };
    AdvanceSearchService.prototype.advanceSearchDetailById = function (advanceSearchId, selected) {
        return this.serverService.get(shared_1.Global.advanceSearchEndpoint + "/" + advanceSearchId + "/report/" + encodeURIComponent(selected) + "/selected", "");
    };
    AdvanceSearchService.prototype.saveAdvanceSearch = function (advanceSearch) {
        return this.serverService.put("" + shared_1.Global.advanceSearchEndpoint, advanceSearch.id, advanceSearch);
    };
    AdvanceSearchService.prototype.cloneAdvanceSearch = function (advanceSearch) {
        return this.serverService.put(shared_1.Global.advanceSearchEndpoint + "/clone", advanceSearch.id, advanceSearch);
    };
    AdvanceSearchService.prototype.deleteAdvanceSearch = function (id) {
        return this.serverService.delete(shared_1.Global.advanceSearchEndpoint + "/", id);
    };
    AdvanceSearchService.prototype.getAdvanceSearchList = function () {
        return this.serverService.get(shared_1.Global.advanceSearchEndpoint + "/list", "");
    };
    AdvanceSearchService.prototype.getAdvanceSearch = function (id) {
        return this.serverService.get(shared_1.Global.advanceSearchEndpoint + "/" + id, "");
    };
    AdvanceSearchService.prototype.addOrUpdatePrivateShare = function (id, privateShared) {
        return this.serverService.put(shared_1.Global.advanceSearchEndpoint + "/private/" + id + "/shared", id, privateShared);
    };
    AdvanceSearchService.prototype.deletePrivateShare = function (id, userId) {
        return this.serverService.delete(shared_1.Global.advanceSearchEndpoint + "/private/" + id + "/shared", userId);
    };
    AdvanceSearchService.prototype.updateSharedStatus = function (id, value) {
        return this.serverService.put(shared_1.Global.advanceSearchEndpoint + "/" + id + "/shared", id, value);
    };
    AdvanceSearchService.prototype.getGridLayoutInfo = function () {
        return this.serverService.get(shared_1.Global.advanceSearchEndpoint + "/gridlayoutinfo", "");
    };
    AdvanceSearchService.prototype.getAlertResult = function (id) {
        return this.serverService.get(shared_1.Global.advanceSearchAlertEndpoint + "/" + id + "/result", "");
    };
    AdvanceSearchService = __decorate([
        core_1.Injectable(),
        __metadata("design:paramtypes", [server_service_1.ServerService])
    ], AdvanceSearchService);
    return AdvanceSearchService;
}());
exports.AdvanceSearchService = AdvanceSearchService;
