"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//bootstrap
require("bootstrap");
require("./assets/bootstrap.min.css");
//font awesome
require("font-awesome/css/font-awesome.css");
//primeng
require("primeng/resources/themes/omega/theme.css");
require("./assets/primeng-omega.css");
require("primeng/resources/primeng.min.css");
require("primeicons/primeicons.css");
require("fullcalendar/dist/fullcalendar.min.css");
require("quill/dist/quill.snow.css");
//current using styles
require("./assets/custom.css");
