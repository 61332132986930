"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Subject_1 = require("rxjs/Subject");
var NotificationsService = /** @class */ (function () {
    function NotificationsService() {
        this.growl = new Subject_1.Subject();
        this.message = new Subject_1.Subject();
    }
    NotificationsService.prototype.notify = function (severity, summary, detail, life) {
        this.growl.next({ message: { severity: severity, summary: summary, detail: detail }, life: life });
    };
    NotificationsService.prototype.success = function (summary, detail) {
        this.growl.next({
            message: { severity: "success", summary: summary, detail: detail },
            sticky: false
        });
    };
    NotificationsService.prototype.info = function (summary, detail) {
        this.growl.next({
            message: { severity: "info", summary: summary, detail: detail },
            sticky: false
        });
    };
    NotificationsService.prototype.error = function (detail) {
        this.growl.next({
            message: { severity: "error", summary: "Error", detail: detail },
            sticky: false
        });
    };
    NotificationsService.prototype.successMsg = function (detail) {
        this.message.next({
            message: { severity: "success", detail: detail },
            sticky: false
        });
    };
    NotificationsService.prototype.infoMsg = function (detail) {
        this.message.next({
            message: { severity: "info", detail: detail },
            sticky: false
        });
    };
    NotificationsService.prototype.warnMsg = function (detail) {
        this.message.next({
            message: { severity: "warn", detail: detail },
            sticky: false
        });
    };
    NotificationsService.prototype.errorMsg = function (key, detail) {
        this.message.next({
            message: { key: key, severity: "error", detail: detail },
            sticky: true
        });
    };
    NotificationsService.prototype.clearMsg = function () {
        this.message.next({ clear: true });
    };
    NotificationsService = __decorate([
        core_1.Injectable()
    ], NotificationsService);
    return NotificationsService;
}());
exports.NotificationsService = NotificationsService;
