"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ReportDetailComponent = /** @class */ (function () {
    function ReportDetailComponent() {
    }
    ReportDetailComponent.prototype.colNgStyle = function (col) {
        return { 'display': (col.hidden || false ? "none" : "table-cell") };
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], ReportDetailComponent.prototype, "data", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Boolean)
    ], ReportDetailComponent.prototype, "loading", void 0);
    ReportDetailComponent = __decorate([
        core_1.Component({
            selector: "report-detail",
            template: require('./report-detail.component.html')
        })
    ], ReportDetailComponent);
    return ReportDetailComponent;
}());
exports.ReportDetailComponent = ReportDetailComponent;
