"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var shared_1 = require("../../shared");
var services_1 = require("../../services");
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, route, router, userService, notificationsService) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.userService = userService;
        this.notificationsService = notificationsService;
        this.submitted = false;
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.loginForm = this.formBuilder.group({
            username: ["", forms_1.Validators.required],
            password: ["", forms_1.Validators.required]
        });
        this.msgKey = "login";
        this.userService.logout();
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/dashboard";
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.loginForm.controls; },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        this.notificationsService.clearMsg();
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        this.userService.authorize(this.f.username.value, this.f.password.value).subscribe(function (user) {
            _this.router.navigate([_this.returnUrl]);
            localStorage.setItem(shared_1.Global.currentUserLocalStorageItemName, JSON.stringify(user));
        }, function (error) {
            _this.notificationsService.errorMsg(_this.msgKey, error);
            _this.submitted = false;
            _this.loginForm = _this.formBuilder.group({
                username: [_this.f.username.value, forms_1.Validators.required],
                password: ["", forms_1.Validators.required]
            });
        });
    };
    LoginComponent = __decorate([
        core_1.Component({ template: require('./login.component.html') }),
        __metadata("design:paramtypes", [forms_1.FormBuilder,
            router_1.ActivatedRoute,
            router_1.Router,
            services_1.UserService,
            services_1.NotificationsService])
    ], LoginComponent);
    return LoginComponent;
}());
exports.LoginComponent = LoginComponent;
