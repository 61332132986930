"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var shared_1 = require("../shared");
var server_service_1 = require("./server.service");
var UserService = /** @class */ (function () {
    function UserService(serverService) {
        this.serverService = serverService;
    }
    UserService.prototype.authorize = function (username, password) {
        return this.serverService.post(shared_1.Global.userValidationEndpoint, { username: username, password: password });
    };
    UserService.prototype.getUsers = function (filter) {
        return this.serverService.post(shared_1.Global.userGetListEndpoint, filter);
    };
    UserService.prototype.getUserCompanies = function (userId) {
        return this.serverService.getById(shared_1.Global.userGetCompaniesEndpoint, userId);
    };
    UserService.prototype.countUsers = function (filter) {
        return this.serverService.post(shared_1.Global.userCountEndpoint, filter);
    };
    UserService.prototype.saveUser = function (user) {
        if (user.id == null) {
            return this.serverService.post(shared_1.Global.userEndpoint, user);
        }
        return this.serverService.put(shared_1.Global.userEndpoint, user.Id, user);
    };
    UserService.prototype.deleteUser = function (id) {
        return this.serverService.delete(shared_1.Global.userEndpoint, id);
    };
    UserService.prototype.logout = function () {
        localStorage.removeItem(shared_1.Global.currentUserLocalStorageItemName);
        this.serverService.post(shared_1.Global.userLogoutEndpoint, {}).subscribe();
    };
    UserService.prototype.searchByNameWithinSameCompany = function (query) {
        return this.serverService.get(shared_1.Global.userEndpoint + "/search/company/" + query, []);
    };
    UserService = __decorate([
        core_1.Injectable(),
        __metadata("design:paramtypes", [server_service_1.ServerService])
    ], UserService);
    return UserService;
}());
exports.UserService = UserService;
