"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var services_1 = require("../../services");
var bases_1 = require("../bases");
var CompanyManagementComponent = /** @class */ (function (_super) {
    __extends(CompanyManagementComponent, _super);
    function CompanyManagementComponent(companyService, notificationsService, route) {
        var _this = _super.call(this, notificationsService, route) || this;
        _this.companyService = companyService;
        _this.cols = _this.gridLayoutInfo;
        return _this;
    }
    CompanyManagementComponent.prototype.ngOnInit = function () {
        this.notificationsService.info("Component", "User Management Component is loading...");
        this.company = {};
        this.companies = [];
    };
    ;
    //#region Grid Control
    CompanyManagementComponent.prototype.filter = function () {
        return { filter: this.lazyLoadEvent, cols: this.cols };
    };
    CompanyManagementComponent.prototype.lazyLoad = function (event) {
        var _this = this;
        this.lazyLoadEvent = event;
        this.loading = true;
        this.companyService.getList(this.filter())
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.totalRecords = result.totalCount;
            _this.companies = result.result;
            if (_this.refreshing) {
                _this.notificationsService.successMsg("Refreshed successfully!");
                _this.refreshing = false;
            }
            _this.loading = false;
        }, function (error) {
            _this.companies = [];
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
        });
    };
    //#endregion
    //#region Company CRUD
    CompanyManagementComponent.prototype.new = function () {
        this.newCompany = true;
        this.company = {};
        this.dialogVisible = true;
    };
    CompanyManagementComponent.prototype.edit = function () {
        this.newCompany = false;
        this.company = this.clone(this.selectedCompany);
        this.dialogVisible = true;
    };
    CompanyManagementComponent.prototype.delete = function () {
        var _this = this;
        this.dialogVisible = false;
        this.companyService.delete(this.selectedCompany.id).subscribe(function () {
            _this.dialogVisible = false;
            _this.notificationsService.successMsg("Company is deleted successfully!");
            _this.lazyLoad(_this.lazyLoadEvent);
        }, function (error) {
            _this.notificationsService.error("Failed to delete selected company!");
            console.log(error);
        });
    };
    CompanyManagementComponent.prototype.save = function () {
        var _this = this;
        this.companyService.save(this.company).subscribe(function (company) {
            _this.dialogVisible = false;
            _this.lazyLoad(_this.lazyLoadEvent);
            if (_this.newCompany) {
                _this.notificationsService.successMsg("Company is created successfully!");
                return;
            }
            _this.notificationsService.successMsg("Company is modified successfully!");
            _this.selectedCompany = _this.clone(company);
        }, function (error) {
            _this.notificationsService.error("Failed to add/modify selected company!");
            console.log(error);
        });
    };
    CompanyManagementComponent.prototype.close = function () {
        var _this = this;
        this.dialogVisible = false;
        setTimeout(function () { _this.newCompany = false; }, 1000);
    };
    CompanyManagementComponent.prototype.clone = function (c) {
        var company = {};
        for (var prop in c) {
            if (c.hasOwnProperty(prop)) {
                company[prop] = c[prop];
            }
        }
        return company;
    };
    //#endregion
    CompanyManagementComponent.prototype.onShow = function () {
        var _this = this;
        this.companyService.get(this.company.id).subscribe(function (result) {
            _this.company = result;
        }, function (error) {
            _this.companies = [];
            _this.notificationsService.error(error);
            console.log(error);
        });
    };
    CompanyManagementComponent.prototype.dialogHeader = function () {
        return this.newCompany ? "New Company" : "Company Detail";
    };
    CompanyManagementComponent.prototype.observingRegions = function () {
        return this.selectedCompany.observingRegions.map(function (c) { return c.name; }).join(" / ");
    };
    CompanyManagementComponent.prototype.validate = function (f) {
        return f.invalid;
    };
    CompanyManagementComponent = __decorate([
        core_1.Component({
            template: require('./companymanagement.component.html'),
            styles: [
                "\n        :host ::ng-deep p-button, .ui-button{float:left;margin-right:0.5em;}\n        :host ::ng-deep .ui-table-caption{background:none;border:none;min-height:3.0em;}\n        :host ::ng-deep .ui-paginator-left-content{color:black;font-size:.75em;padding-left:0.75em;padding-top:0.75em;}\n        :host ::ng-deep .ui-picklist.ui-picklist-responsive .ui-picklist-buttons{width:20px;}\n        "
            ]
        }),
        __metadata("design:paramtypes", [services_1.CompanyService,
            services_1.NotificationsService,
            router_1.ActivatedRoute])
    ], CompanyManagementComponent);
    return CompanyManagementComponent;
}(bases_1.BasePrimeNgGridComponent));
exports.CompanyManagementComponent = CompanyManagementComponent;
