"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../../services");
var messageservice_1 = require("primeng/components/common/messageservice");
var NotificationsComponent = /** @class */ (function () {
    function NotificationsComponent(notificationsService, messageService) {
        this.notificationsService = notificationsService;
        this.messageService = messageService;
    }
    NotificationsComponent.prototype.ngOnInit = function () {
        this.subscribeToNotifications();
    };
    NotificationsComponent.prototype.subscribeToNotifications = function () {
        var _this = this;
        this.subscription = this.notificationsService.growl
            .subscribe(function (notification) {
            var data = notification;
            _this.messageService.clear();
            _this.messageService.add(data.message);
            _this.life = data.life || 2000;
            _this.sticky = data.sticky;
        });
    };
    NotificationsComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    NotificationsComponent = __decorate([
        core_1.Component({
            selector: "app-notifications",
            template: require('./notifications.component.html'),
            providers: [messageservice_1.MessageService]
        }),
        __metadata("design:paramtypes", [services_1.NotificationsService,
            messageservice_1.MessageService])
    ], NotificationsComponent);
    return NotificationsComponent;
}());
exports.NotificationsComponent = NotificationsComponent;
