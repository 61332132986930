"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
require("rxjs/add/operator/map");
require("rxjs/add/operator/do");
require("rxjs/add/operator/catch");
var ServerService = /** @class */ (function () {
    function ServerService(httpClient) {
        this.httpClient = httpClient;
    }
    ServerService.prototype.get = function (url, filter) {
        var params = new http_1.HttpParams();
        for (var key in filter) {
            if (filter.hasOwnProperty(key)) {
                params = params.set(key, filter[key]);
            }
        }
        return this.httpClient.get(url, { params: params }).pipe(operators_1.map(function (response) { return response; }), operators_1.catchError(this.handleError));
    };
    ServerService.prototype.getById = function (url, id) {
        return this.httpClient.get(url + "/" + id).pipe(operators_1.map(function (response) { return response; }), operators_1.catchError(this.handleError));
    };
    ServerService.prototype.post = function (url, model) {
        return this.httpClient.post(url, JSON.stringify(model)).pipe(operators_1.map(function (response) { return response; }), operators_1.catchError(this.handleError));
    };
    ServerService.prototype.put = function (url, id, model) {
        var body = JSON.stringify(model);
        return this.httpClient.put(url, body).pipe(operators_1.map(function (response) { return response; }), operators_1.catchError(this.handleError));
    };
    ServerService.prototype.delete = function (url, id) {
        return this.httpClient.delete(url + "/" + id).pipe(operators_1.map(function (response) { return response; }), operators_1.catchError(this.handleError));
    };
    ServerService.prototype.handleError = function (error) {
        var errors = error;
        console.log(errors);
        return rxjs_1.throwError(errors[0].displayText || "Internal Server Error");
    };
    ServerService = __decorate([
        core_1.Injectable(),
        __metadata("design:paramtypes", [http_1.HttpClient])
    ], ServerService);
    return ServerService;
}());
exports.ServerService = ServerService;
