"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var InputValidationComponent = /** @class */ (function () {
    function InputValidationComponent() {
        this.errorMessages = [];
        this.errorMessage = "";
    }
    InputValidationComponent.prototype.hasError = function () {
        var _this = this;
        this.errorMessages = [];
        this.errorMessage = "";
        if (!this.control) {
            return false;
        }
        if (this.errDef && (this.control.errors || this.errDef["custom"])) {
            Object.keys(this.errDef).some(function (key) {
                if (_this.control.errors && _this.control.errors[key]) {
                    _this.errorMessages.push(_this.errDef[key]);
                }
                else if (key === "custom" && !_this.runCustom()) {
                    _this.errorMessages.push(_this.errDef[key]);
                }
                return false;
            });
        }
        for (var _i = 0, _a = this.errorMessages; _i < _a.length; _i++) {
            var m = _a[_i];
            if (this.errorMessage.length > 0) {
                this.errorMessage = this.errorMessage + ".  ";
            }
            this.errorMessage = this.errorMessage + m;
        }
        return this.errorMessages.length > 0 && this.control.dirty;
    };
    InputValidationComponent.prototype.runCustom = function () {
        return this.custom(this);
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", forms_1.NgModel)
    ], InputValidationComponent.prototype, "control", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], InputValidationComponent.prototype, "errDef", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], InputValidationComponent.prototype, "custom", void 0);
    InputValidationComponent = __decorate([
        core_1.Component({
            selector: "app-input-validation",
            template: "\n    <div *ngIf=\"hasError()\" class=\"ui-message ui-messages-error\">\n      {{ errorMessage }}\n    </div>\n  ",
            styles: ["\n    .ui-messages-error {\n      margin: 0;\n      margin-top: 4px;\n    }\n  "]
        })
    ], InputValidationComponent);
    return InputValidationComponent;
}());
exports.InputValidationComponent = InputValidationComponent;
