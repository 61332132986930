"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../../services");
var messageservice_1 = require("primeng/components/common/messageservice");
var MessagesComponent = /** @class */ (function () {
    function MessagesComponent(notificationsService, messageService) {
        this.notificationsService = notificationsService;
        this.messageService = messageService;
    }
    MessagesComponent.prototype.ngOnInit = function () {
        this.subscribeToNotifications();
    };
    MessagesComponent.prototype.subscribeToNotifications = function () {
        var _this = this;
        this.subscription = this.notificationsService.message
            .subscribe(function (message) {
            var data = message;
            if (data.clear) {
                _this.messageService.clear();
                return;
            }
            _this.messageService.add(data.message);
            if (data.sticky) {
                return;
            }
            setTimeout(function () { _this.messageService.clear(); }, data.life || 2000);
        });
    };
    MessagesComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", String)
    ], MessagesComponent.prototype, "id", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Boolean)
    ], MessagesComponent.prototype, "closable", void 0);
    MessagesComponent = __decorate([
        core_1.Component({
            selector: "app-messages",
            template: require('./messages.component.html'),
            providers: [messageservice_1.MessageService]
        }),
        __metadata("design:paramtypes", [services_1.NotificationsService,
            messageservice_1.MessageService])
    ], MessagesComponent);
    return MessagesComponent;
}());
exports.MessagesComponent = MessagesComponent;
