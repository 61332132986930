"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var services_1 = require("../../services");
var bases_1 = require("../bases");
var AdvanceSearchComponent = /** @class */ (function (_super) {
    __extends(AdvanceSearchComponent, _super);
    function AdvanceSearchComponent(transactionService, userService, notificationsService, route) {
        var _this = _super.call(this, notificationsService, route) || this;
        _this.transactionService = transactionService;
        _this.userService = userService;
        _this.expandedRows = {};
        _this.selectedTransactionProduct = {};
        _this.selectedAdvanceSearch = {};
        _this.transactionProductListGridInfo = _this.gridLayoutInfo;
        _this.cols = _this.transactionProductListGridInfo.byAdvanceSearchGridDetailColumns;
        _this.advanceSearch = _this.getNewAdvanceSearch();
        _this.transactionProducts = [];
        _this.newPrivateShare = _this.getNewPrivateShare();
        _this.sharable = false;
        _this.isPeriodTemplate = false;
        _this.detailLoading = false;
        _this.reports = [];
        _this.getList();
        return _this;
    }
    AdvanceSearchComponent.prototype.addOrUpdatePrivateShare = function (privateShare) {
        var _this = this;
        this.transactionService.addOrUpdatePrivateShare(this.selectedAdvanceSearch.value, privateShare)
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.advanceSearch.advanceSearchShared = result;
            _this.newPrivateShare = _this.getNewPrivateShare();
            _this.selectedUser = { label: "", value: "" };
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
            return;
        });
    };
    AdvanceSearchComponent.prototype.deletePrivateShare = function (privateShare) {
        var _this = this;
        this.transactionService.deletePrivateShare(this.selectedAdvanceSearch.value, privateShare.userId)
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.advanceSearch.advanceSearchShared = result;
            _this.newPrivateShare = _this.getNewPrivateShare();
            _this.selectedUser = { label: "", value: "" };
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
            return;
        });
    };
    AdvanceSearchComponent.prototype.updateSharedStatus = function () {
        this.transactionService.updateSharedStatus(this.selectedAdvanceSearch.value, this.advanceSearch.shared).subscribe();
    };
    AdvanceSearchComponent.prototype.getNewPrivateShare = function () {
        return {
            read: true,
            write: false,
            share: false,
        };
    };
    AdvanceSearchComponent.prototype.getList = function (selected) {
        var _this = this;
        this.transactionService.getAdvanceSearchList()
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.advanceSearchList = result;
            _this.filteredAdvanceSearchList = Object.assign([], _this.advanceSearchList);
            if (selected == null) {
                _this.selectedAdvanceSearch = _this.filteredAdvanceSearchList[0];
                _this.loadAdvanceSearch(_this.selectedAdvanceSearch.value);
                return;
            }
            _this.selectedAdvanceSearch = {
                label: selected.name,
                value: selected.id.toString()
            };
        }, function (error) {
            _this.advanceSearchList = [];
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
        });
    };
    AdvanceSearchComponent.prototype.getNewAdvanceSearch = function () {
        return {
            id: -1,
            name: "New Search",
            description: "New Search Description",
            data: {
                elements: [],
                groups: [],
                logic: "and"
            },
            shared: false,
            sharedStatus: "readWriteShare",
            advanceSearchShared: {},
            alert: false,
            alertData: []
        };
    };
    AdvanceSearchComponent.prototype.onBlur = function () {
        if (this.selectedAdvanceSearch.label == null) {
            this.selectedAdvanceSearch = {
                label: this.advanceSearch.name,
                value: this.advanceSearch.id.toString()
            };
        }
    };
    AdvanceSearchComponent.prototype.loadUser = function (event) {
        this.newPrivateShare.userId = event.value;
        this.newPrivateShare.userName = event.label;
    };
    AdvanceSearchComponent.prototype.loadAdvanceSearch = function (id) {
        var _this = this;
        if (id === -1) {
            this.advanceSearch = this.getNewAdvanceSearch();
            this.selectedAdvanceSearch = {
                label: this.advanceSearch.name,
                value: this.advanceSearch.id.toString()
            };
            return;
        }
        this.transactionService.getAdvanceSearch(id)
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.advanceSearch = result;
            _this.filteredAdvanceSearchList = Object.assign([], _this.advanceSearchList);
            _this.selectedAdvanceSearch = {
                label: _this.advanceSearch.name,
                value: _this.advanceSearch.id.toString()
            };
            _this.sharable = _this.advanceSearch.sharedStatus.includes("Share");
            _this.updateTemplate(_this.advanceSearch.thenGroupByOption);
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
            return;
        });
    };
    AdvanceSearchComponent.prototype.saveAdvanceSearch = function () {
        var _this = this;
        this.transactionService.saveAdvanceSearch(this.advanceSearch)
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.advanceSearch = result;
            _this.getList(result);
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
        });
    };
    AdvanceSearchComponent.prototype.cloneAdvanceSearch = function () {
        var _this = this;
        this.transactionService.cloneAdvanceSearch(this.advanceSearch)
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.advanceSearch = result;
            _this.getList(result);
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
        });
    };
    AdvanceSearchComponent.prototype.deleteAdvanceSearch = function () {
        var _this = this;
        this.transactionService.deleteAdvanceSearch(this.advanceSearch.id)
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.getList();
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
        });
    };
    AdvanceSearchComponent.prototype.filterAdvanceSearches = function (query) {
        if (query == "") {
            this.filteredAdvanceSearchList = Object.assign([], this.advanceSearchList);
            return;
        }
        this.filteredAdvanceSearchList = [];
        for (var i = 0; i < this.advanceSearchList.length; i++) {
            var advanceSearch = this.advanceSearchList[i];
            if (advanceSearch.label.toLowerCase().indexOf(query.toLowerCase()) > 0) {
                this.filteredAdvanceSearchList.push(advanceSearch);
            }
        }
    };
    AdvanceSearchComponent.prototype.lazyLoad = function (event) {
        var _this = this;
        this.transactionProducts = [];
        this.selectedTransactionProduct = {};
        this.expandedRows = {};
        if (this.advanceSearch.data.elements.length === 0 && this.advanceSearch.data.groups.length === 0) {
            this.notificationsService.warnMsg("Please add filter detail...");
            this.refreshing = false;
            return;
        }
        this.transactionService.advanceSearch({
            searchGroup: this.advanceSearch.data,
            filter: {
                cols: this.cols,
                filter: this.lazyLoadEvent
            },
            periodGroupByOption: this.advanceSearch.periodGroupByOption,
            thenGroupByOption: this.advanceSearch.thenGroupByOption,
        })
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.totalRecords = result.totalCount;
            _this.transactionProducts = result.result;
            if (_this.refreshing) {
                _this.notificationsService.successMsg("Refreshed successfully!");
                _this.refreshing = false;
            }
            _this.loading = false;
        }, function (error) {
            _this.transactionProducts = [];
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
        });
    };
    AdvanceSearchComponent.prototype.filterUsers = function (query) {
        var _this = this;
        this.userService.searchByNameWithinSameCompany(query).map(function (result) { return result; })
            .subscribe(function (result) {
            _this.filteredUserList = result;
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
        });
    };
    AdvanceSearchComponent.prototype.updateTemplate = function (value) {
        var cols = this.transactionProductListGridInfo.byAdvanceSearchGridDetailColumns;
        this.isPeriodTemplate = value.indexOf("ShipDate") > 0;
        if (this.isPeriodTemplate) {
            this.advanceSearch.periodGroupByOption = "week";
        }
        this.cols = this.transactionProductListGridInfo.byAdvanceSearchGridDetailColumns;
        switch (this.advanceSearch.thenGroupByOption) {
            case "none":
                this.cols = [cols[0], cols[3], cols[5], cols[6], cols[7]];
                break;
            case "ndcNumber":
                this.cols = [cols[0], cols[1], cols[2], cols[5], cols[6], cols[7], cols[8]];
                break;
            case "recipientZipCode":
                this.cols = [cols[0], cols[4], cols[9], cols[5], cols[6], cols[7], cols[8]];
                break;
        }
        this.refresh();
    };
    AdvanceSearchComponent.prototype.alertStatusChanged = function (checked) {
        if (checked && this.advanceSearch.alertData == null) {
            this.advanceSearch.alertData = [
                {
                    logic: this.transactionProductListGridInfo.logicOperatorOptions[0].value,
                    value: 0
                }
            ];
        }
    };
    AdvanceSearchComponent.prototype.onRowSelect = function (event) {
        this.reports = [];
        this.detailLoading = true;
        switch (this.advanceSearch.thenGroupByOption) {
            case "none":
                this.bySelectedDetail(event.data.period);
                break;
            case "ndcNumber":
                this.bySelectedDetail(event.data.ndcNumber);
                break;
            case "recipientZipCode":
                this.bySelectedDetail(event.data.zipCode);
                break;
        }
    };
    AdvanceSearchComponent.prototype.bySelectedDetail = function (selected) {
        var _this = this;
        this.transactionService.advanceSearchDetail({
            searchGroup: this.advanceSearch.data,
            filter: {
                cols: this.cols,
                filter: this.lazyLoadEvent
            },
            periodGroupByOption: this.advanceSearch.periodGroupByOption,
            thenGroupByOption: this.advanceSearch.thenGroupByOption,
        }, selected)
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.reports = result.reports;
            _this.detailLoading = false;
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.detailLoading = false;
        });
    };
    AdvanceSearchComponent.prototype.mapStatus = function () {
        return this.advanceSearch.thenGroupByOption === "recipientZipCode";
    };
    AdvanceSearchComponent.prototype.onTabChange = function (event) {
        this.mapTabSelected = event.index === 1;
    };
    AdvanceSearchComponent.prototype.getAlertDetail = function () {
        var _this = this;
        this.transactionService.getAlertResult(this.advanceSearch.id)
            .subscribe(function (result) {
            console.log(result);
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.detailLoading = false;
        });
    };
    AdvanceSearchComponent.prototype.ngOnInit = function () {
    };
    AdvanceSearchComponent = __decorate([
        core_1.Component({
            template: require('./advance-search.component.html')
        }),
        __metadata("design:paramtypes", [services_1.AdvanceSearchService,
            services_1.UserService,
            services_1.NotificationsService,
            router_1.ActivatedRoute])
    ], AdvanceSearchComponent);
    return AdvanceSearchComponent;
}(bases_1.BasePrimeNgGridComponent));
exports.AdvanceSearchComponent = AdvanceSearchComponent;
