"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../../services");
var TransactionMapComponent = /** @class */ (function () {
    function TransactionMapComponent(mapReportService, notificationsService) {
        this.mapReportService = mapReportService;
        this.notificationsService = notificationsService;
        this.visible = false;
        this.loading = false;
        this.mapReport = {};
        this.loading = true;
    }
    Object.defineProperty(TransactionMapComponent.prototype, "advanceSearchId", {
        set: function (value) {
            if (value !== -1 && this.mapReport != null) {
                if (this.mapReport.advanceSearchId === value) {
                    return;
                }
                this.mapReport.advanceSearchId = value;
                this.mapReport.id = null;
                this.initMapReport();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransactionMapComponent.prototype, "selected", {
        set: function (value) {
            this.visible = value;
            this.initMapReport();
        },
        enumerable: true,
        configurable: true
    });
    TransactionMapComponent.prototype.reload = function () {
        if (this.cachedMapReport.data.length < this.mapReport.setSize) {
            this.reloadMapReport();
        }
        else {
            this.mapReport = {
                id: this.mapReport.id,
                advanceSearchId: this.mapReport.advanceSearchId,
                color: this.mapReport.color,
                overlayColor: this.mapReport.overlayColor,
                setSize: this.mapReport.setSize,
                dashboardDisplay: this.mapReport.dashboardDisplay,
                icon: this.mapReport.icon,
                advanceSearchName: this.mapReport.advanceSearchName,
                data: this.cachedMapReport.data.slice(0, this.mapReport.setSize),
                visible: this.mapReport.visible
            };
        }
    };
    TransactionMapComponent.prototype.reloadMapReport = function () {
        var _this = this;
        this.loading = true;
        this.mapReportService.reloadMapReport({
            id: this.mapReport.id,
            advanceSearchId: this.mapReport.advanceSearchId,
            color: this.mapReport.color,
            overlayColor: this.mapReport.overlayColor,
            setSize: this.mapReport.setSize,
            dashboardDisplay: this.mapReport.dashboardDisplay
        })
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.cachedMapReport = result;
            _this.mapReport = result;
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
            return;
        }, function () { return _this.loading = false; });
    };
    TransactionMapComponent.prototype.createOrUpdate = function () {
        var _this = this;
        this.loading = true;
        this.mapReportService.createOrUpdate({
            id: this.mapReport.id,
            advanceSearchId: this.mapReport.advanceSearchId,
            color: this.mapReport.color,
            overlayColor: this.mapReport.overlayColor,
            setSize: this.mapReport.setSize,
            dashboardDisplay: this.mapReport.dashboardDisplay
        })
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.mapReport.id = result.id;
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
            return;
        }, function () { return _this.loading = false; });
    };
    TransactionMapComponent.prototype.initMapReport = function () {
        var _this = this;
        if (this.visible && this.mapReport.advanceSearchId !== null && this.mapReport.id === null) {
            this.loading = true;
            this.mapReportService.getMapReportByAdvanceSearchId(this.mapReport.advanceSearchId)
                .map(function (result) { return result; })
                .subscribe(function (result) {
                _this.cachedMapReport = result;
                _this.mapReport = result;
            }, function (error) {
                _this.notificationsService.error(error);
                console.log(error);
                _this.loading = false;
                return;
            }, function () { return _this.loading = false; });
        }
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [Number])
    ], TransactionMapComponent.prototype, "advanceSearchId", null);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [Boolean])
    ], TransactionMapComponent.prototype, "selected", null);
    TransactionMapComponent = __decorate([
        core_1.Component({
            selector: "transaction-map",
            template: require('./transaction-map.component.html')
        }),
        __metadata("design:paramtypes", [services_1.MapReportService,
            services_1.NotificationsService])
    ], TransactionMapComponent);
    return TransactionMapComponent;
}());
exports.TransactionMapComponent = TransactionMapComponent;
