"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var BaseBingMapComponent = /** @class */ (function () {
    function BaseBingMapComponent(mapReportService, notificationsService) {
        this.mapReportService = mapReportService;
        this.notificationsService = notificationsService;
        this.loading = false;
        this.loading = true;
    }
    Object.defineProperty(BaseBingMapComponent.prototype, "mapId", {
        set: function (value) {
            this.bingMapId = "bingMap" + value;
        },
        enumerable: true,
        configurable: true
    });
    BaseBingMapComponent.prototype.setMapReports = function () {
        var _this = this;
        this.map.layers.clear();
        var centers = new Array();
        this.mapReports.forEach(function (report) {
            if (report.data === undefined)
                return;
            report.infoBox = new Microsoft.Maps.Infobox(_this.map.getCenter(), {
                visible: false
            });
            report.infoBox.setMap(_this.map);
            report.layer = new Microsoft.Maps.Layer();
            report.locations = new Array();
            report.data.forEach(function (zc) {
                zc.overlayColor = report.overlayColor;
                zc.strokeColor = report.color;
                centers.push(_this.mapReportService.setPolygon(report, zc));
            });
            _this.map.layers.insert(report.layer);
            Microsoft.Maps.loadModule("Microsoft.Maps.HeatMap", function () {
                report.heatMap = new Microsoft.Maps.HeatMapLayer(report.locations, {
                    intensity: 0.2,
                    radius: 30,
                    colorGradient: {
                        '0': "Black",
                        '0.2': "Green",
                        '0.4': "Yellow",
                        '0.6': "Orange",
                        '0.8': "Red",
                        '1': "White"
                    }
                });
                _this.map.layers.insert(report.heatMap);
            });
        });
        switch (centers.length) {
            case 0:
                break;
            case 1:
                this.map.setView({
                    center: centers[0]
                });
                break;
            case 2:
                this.map.setView({
                    center: new Microsoft.Maps.Location((centers[0].latitude + centers[1].latitude) / 2.0, (centers[0].longitude + centers[1].longitude) / 2.0)
                });
            default:
                var centerPolygon_1 = new Microsoft.Maps.Polygon(centers);
                Microsoft.Maps.loadModule("Microsoft.Maps.SpatialMath", function () {
                    _this.map.setView({
                        center: Microsoft.Maps.SpatialMath.Geometry.centroid(centerPolygon_1)
                    });
                });
        }
    };
    BaseBingMapComponent.prototype.ngAfterViewInit = function () {
        this.map = new Microsoft.Maps.Map(document.getElementById(this.bingMapId), {
            disableBirdseye: true,
            disableStreetside: true,
            showMapTypeSelector: false,
            mapTypeId: Microsoft.Maps.MapTypeId.road,
            zoom: 10
        });
        if (this.map !== undefined) {
            this.setMapReports();
        }
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [String])
    ], BaseBingMapComponent.prototype, "mapId", null);
    return BaseBingMapComponent;
}());
exports.BaseBingMapComponent = BaseBingMapComponent;
