"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../../services");
var UserManagementComponent = /** @class */ (function () {
    function UserManagementComponent(userService, notificationsService) {
        this.userService = userService;
        this.notificationsService = notificationsService;
        this.passwordPattern = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$";
    }
    UserManagementComponent.prototype.ngOnInit = function () {
        this.notificationsService.info("Component", "User Management Component is loading...");
        this.cols = [
            { field: "firstName", header: "First Name", filterMatchMode: "contains" },
            { field: "lastName", header: "Last Name", filterMatchMode: "contains" },
            { field: "email", header: "Email", filterMatchMode: "contains" },
            { field: "companyName", header: "Company", filterMatchMode: "contains" },
            { field: "lastLogin", header: "Last Login", filterMatchMode: "cointains" }
        ];
        this.totalRecords = 0;
        this.user = {};
        this.users = [];
    };
    ;
    //#region Grid Control
    UserManagementComponent.prototype.refresh = function () {
        this.refreshing = true;
        this.lazyLoadUsers(this.lazyLoadEvent);
    };
    UserManagementComponent.prototype.filter = function () {
        return { filter: this.lazyLoadEvent, cols: this.cols };
    };
    UserManagementComponent.prototype.lazyLoadUsers = function (event) {
        var _this = this;
        this.lazyLoadEvent = event;
        this.loading = true;
        this.userService.countUsers(this.filter()).subscribe(function (count) {
            if (count === 0) {
                _this.totalRecords = 0;
                _this.users = [];
                return;
            }
            _this.totalRecords = count;
            _this.userService.getUsers(_this.filter()).subscribe(function (users) {
                _this.users = users;
                if (_this.refreshing) {
                    _this.notificationsService.successMsg("Refreshed successfully!");
                    _this.refreshing = false;
                }
            }, function (error) {
                _this.users = [];
                _this.notificationsService.error(error);
                console.log(error);
            });
        }, function (error) {
            _this.totalRecords = 0;
            _this.notificationsService.error(error);
            console.log(error);
        });
    };
    //#endregion
    //#region User CRUD
    UserManagementComponent.prototype.new = function () {
        this.newUser = true;
        this.user = {};
        this.dialogVisible = true;
    };
    UserManagementComponent.prototype.edit = function () {
        this.newUser = false;
        this.user = this.clone(this.selectedUser);
        this.dialogVisible = true;
    };
    UserManagementComponent.prototype.delete = function () {
        var _this = this;
        this.dialogVisible = false;
        this.userService.deleteUser(this.selectedUser.id).subscribe(function () {
            _this.dialogVisible = false;
            _this.notificationsService.successMsg("User is deleted successfully!");
            _this.lazyLoadUsers(_this.lazyLoadEvent);
        }, function (error) {
            _this.notificationsService.error("Failed to delete selected user!");
            console.log(error);
        });
    };
    UserManagementComponent.prototype.save = function () {
        var _this = this;
        this.userService.saveUser(this.user).subscribe(function (user) {
            _this.dialogVisible = false;
            _this.lazyLoadUsers(_this.lazyLoadEvent);
            if (_this.newUser) {
                _this.notificationsService.successMsg("User is created successfully!");
                return;
            }
            _this.notificationsService.successMsg("User is modified successfully!");
            _this.selectedUser = _this.clone(user);
        }, function (error) {
            _this.notificationsService.error("Failed to add/modify selected user!");
            console.log(error);
        });
    };
    UserManagementComponent.prototype.close = function () {
        var _this = this;
        this.dialogVisible = false;
        setTimeout(function () { _this.newUser = false; }, 1000);
    };
    UserManagementComponent.prototype.clone = function (c) {
        var user = {};
        for (var prop in c) {
            if (c.hasOwnProperty(prop)) {
                user[prop] = c[prop];
            }
        }
        return user;
    };
    //#endregion
    UserManagementComponent.prototype.onShow = function () {
        var _this = this;
        this.userService.getUserCompanies(this.user.id).subscribe(function (result) {
            var data = result;
            _this.user.assignedCompanies = data.assignedCompanies;
            _this.user.availableCompanies = data.availableCompanies;
        }, function (error) {
            _this.users = [];
            _this.notificationsService.error(error);
            console.log(error);
        });
    };
    UserManagementComponent.prototype.dialogHeader = function () {
        return this.newUser ? "New User" : "User Detail";
    };
    UserManagementComponent.prototype.validatePassword = function () {
        console.log(this.user);
        if (this.user.password === this.repassword) {
            return true;
        }
        return false;
    };
    UserManagementComponent.prototype.validate = function (f) {
        return f.invalid || this.user.password !== this.repassword;
    };
    UserManagementComponent = __decorate([
        core_1.Component({
            template: require('./usermanagement.component.html'),
            styles: [
                "\n        :host ::ng-deep p-button, .ui-button{float:left;margin-right:0.5em;}\n        :host ::ng-deep .ui-table-caption{background:none;border:none;min-height:3.0em;}\n        :host ::ng-deep .ui-paginator-left-content{color:black;font-size:.75em;padding-left:0.75em;padding-top:0.75em;}\n        :host ::ng-deep .ui-picklist.ui-picklist-responsive .ui-picklist-buttons {width: 20px;}\n    "
            ]
        }),
        __metadata("design:paramtypes", [services_1.UserService, services_1.NotificationsService])
    ], UserManagementComponent);
    return UserManagementComponent;
}());
exports.UserManagementComponent = UserManagementComponent;
