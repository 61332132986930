"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var services_1 = require("../../services");
var bases_1 = require("../bases");
var AlertComponent = /** @class */ (function (_super) {
    __extends(AlertComponent, _super);
    function AlertComponent(advanceSearchService, userService, notificationsService, route) {
        var _this = _super.call(this, notificationsService, route) || this;
        _this.advanceSearchService = advanceSearchService;
        _this.userService = userService;
        _this.expandedRows = {};
        _this.selectedTransactionProduct = {};
        _this.transactionProductListGridInfo = _this.gridLayoutInfo;
        var cols = _this.transactionProductListGridInfo.byAdvanceSearchGridDetailColumns;
        _this.cols = [cols[0], cols[4], cols[9], cols[5], cols[6], cols[7], cols[8]];
        return _this;
    }
    AlertComponent.prototype.onRowSelect = function (event) {
        this.reports = [];
        this.detailLoading = true;
        this.bySelectedDetail(event.data.zipCode);
    };
    AlertComponent.prototype.bySelectedDetail = function (selected) {
        var _this = this;
        this.advanceSearchService.advanceSearchDetailById(this.advanceSearchId, selected)
            .map(function (result) { return result; })
            .subscribe(function (result) {
            _this.reports = result.reports;
            _this.detailLoading = false;
        }, function (error) {
            _this.notificationsService.error(error);
            console.log(error);
            _this.detailLoading = false;
        });
    };
    AlertComponent.prototype.lazyLoad = function (event) {
        this.refresh();
    };
    AlertComponent.prototype.refresh = function () {
        var _this = this;
        this.transactionProducts = [];
        this.selectedTransactionProduct = {};
        this.expandedRows = {};
        this.advanceSearchService.getAlertResult(this.advanceSearchId)
            .subscribe(function (result) {
            _this.advanceSearchDescription = result.advanceSearchDescription;
            _this.defaultObservingRegion = result.defaultObservingRegion;
            _this.description = result.description;
            _this.totalRecords = result.data.totalCount;
            _this.transactionProducts = result.data.result;
            _this.alertCondition = result.alertCondition;
            console.log(_this.transactionProducts[0]["zipCode"]);
            if (_this.refreshing) {
                _this.notificationsService.successMsg("Refreshed successfully!");
                _this.refreshing = false;
            }
            _this.loading = false;
        }, function (error) {
            _this.transactionProducts = [];
            _this.notificationsService.error(error);
            console.log(error);
            _this.loading = false;
        });
    };
    AlertComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.advanceSearchId = +params["advanceSearchId"];
        });
        this.refresh();
    };
    AlertComponent = __decorate([
        core_1.Component({
            template: require('./alert.component.html')
        }),
        __metadata("design:paramtypes", [services_1.AdvanceSearchService,
            services_1.UserService,
            services_1.NotificationsService,
            router_1.ActivatedRoute])
    ], AlertComponent);
    return AlertComponent;
}(bases_1.BasePrimeNgGridComponent));
exports.AlertComponent = AlertComponent;
