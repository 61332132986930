"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var components_1 = require("./components");
var resolvers_1 = require("./resolvers");
var guards_1 = require("./guards");
var appRoutes = [
    {
        path: "",
        children: [
            { path: "", pathMatch: "full", redirectTo: "dashboard" },
            { path: "dashboard", component: components_1.DashboardComponent },
            {
                path: "advanceSearch",
                component: components_1.AdvanceSearchComponent,
                resolve: {
                    gridLayoutInfo: resolvers_1.TransactionProductResolve,
                }
            },
            {
                path: "alert",
                component: components_1.AlertComponent,
                resolve: {
                    gridLayoutInfo: resolvers_1.TransactionProductResolve,
                }
            },
            {
                path: "alert/:advanceSearchId/detail",
                component: components_1.AlertComponent,
                resolve: {
                    gridLayoutInfo: resolvers_1.TransactionProductResolve,
                }
            },
            { path: "um", component: components_1.UserManagementComponent },
            {
                path: "cm",
                component: components_1.CompanyManagementComponent,
                resolve: {
                    gridLayoutInfo: resolvers_1.CompanyManagementResolve,
                }
            },
            {
                path: "rm",
                component: components_1.RegionManagementComponent,
                resolve: {
                    gridLayoutInfo: resolvers_1.RegionManagementResolve,
                }
            }
        ],
        component: components_1.HomeComponent,
        canActivate: [guards_1.AuthGuard]
    },
    { path: "login", component: components_1.LoginComponent },
    // otherwise redirect to home
    { path: "**", redirectTo: "" }
];
exports.routing = router_1.RouterModule.forRoot(appRoutes);
