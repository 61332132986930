"use strict";
function __export(m) {
    for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", { value: true });
__export(require("./dashboard"));
__export(require("./home"));
__export(require("./login"));
__export(require("./usermanagement"));
__export(require("./companymanagement"));
__export(require("./regionmanagement"));
__export(require("./transactions"));
__export(require("./alerts"));
