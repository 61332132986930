"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var shared_1 = require("../shared");
var server_service_1 = require("./server.service");
var RegionService = /** @class */ (function () {
    function RegionService(serverService) {
        this.serverService = serverService;
    }
    RegionService.prototype.getList = function (filter) {
        return this.serverService.post(shared_1.Global.regionEndpoint + "/list", filter);
    };
    RegionService.prototype.get = function (id, filter) {
        if (filter == null || filter.query == null) {
            return this.serverService.get(shared_1.Global.regionEndpoint + "/" + id, "");
        }
        return this.serverService.get(shared_1.Global.regionEndpoint + "/" + id + "/" + filter.query, "");
    };
    RegionService.prototype.save = function (region) {
        if (region.id == null) {
            return this.serverService.post(shared_1.Global.regionEndpoint, region);
        }
        return this.serverService.put(shared_1.Global.regionEndpoint, region.Id, region);
    };
    RegionService.prototype.delete = function (id) {
        return this.serverService.delete(shared_1.Global.regionEndpoint, id);
    };
    RegionService.prototype.getGridLayoutInfo = function () {
        return this.serverService.get(shared_1.Global.regionEndpoint + "/gridlayoutinfo", "");
    };
    RegionService = __decorate([
        core_1.Injectable(),
        __metadata("design:paramtypes", [server_service_1.ServerService])
    ], RegionService);
    return RegionService;
}());
exports.RegionService = RegionService;
