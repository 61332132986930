"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var platform_browser_1 = require("@angular/platform-browser");
var animations_1 = require("@angular/platform-browser/animations");
var forms_1 = require("@angular/forms");
var http_1 = require("@angular/common/http");
var app_component_1 = require("./app.component");
var app_routing_1 = require("./app.routing");
var components_1 = require("./components");
var directives_1 = require("./directives");
var _1 = require("./guards/");
var services_1 = require("./services");
var resolvers_1 = require("./resolvers");
var helpers_1 = require("./helpers");
var pipes_1 = require("./pipes");
var selectbutton_1 = require("primeng/selectbutton");
var tabview_1 = require("primeng/tabview");
var table_1 = require("primeng/table");
var paginator_1 = require("primeng/paginator");
var panel_1 = require("primeng/panel");
var dialog_1 = require("primeng/dialog");
var growl_1 = require("primeng/growl");
var messages_1 = require("primeng/messages");
var message_1 = require("primeng/message");
var messageservice_1 = require("primeng/components/common/messageservice");
var inputtext_1 = require("primeng/inputtext");
var picklist_1 = require("primeng/picklist");
var slider_1 = require("primeng/slider");
var fieldset_1 = require("primeng/fieldset");
var calendar_1 = require("primeng/calendar");
var button_1 = require("primeng/button");
var chips_1 = require("primeng/chips");
var splitbutton_1 = require("primeng/splitbutton");
var autocomplete_1 = require("primeng/autocomplete");
var inputswitch_1 = require("primeng/inputswitch");
var scrollpanel_1 = require("primeng/scrollpanel");
var inputtextarea_1 = require("primeng/inputtextarea");
var checkbox_1 = require("primeng/checkbox");
var colorpicker_1 = require("primeng/colorpicker");
var togglebutton_1 = require("primeng/togglebutton");
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    AppModule = __decorate([
        core_1.NgModule({
            imports: [
                platform_browser_1.BrowserModule,
                animations_1.BrowserAnimationsModule,
                forms_1.ReactiveFormsModule,
                http_1.HttpClientModule,
                app_routing_1.routing,
                //PRIMENG
                table_1.TableModule, paginator_1.PaginatorModule, panel_1.PanelModule, dialog_1.DialogModule, growl_1.GrowlModule, message_1.MessageModule,
                messages_1.MessagesModule, inputtext_1.InputTextModule, picklist_1.PickListModule, tabview_1.TabViewModule, selectbutton_1.SelectButtonModule, slider_1.SliderModule,
                fieldset_1.FieldsetModule, calendar_1.CalendarModule, button_1.ButtonModule, chips_1.ChipsModule, splitbutton_1.SplitButtonModule, autocomplete_1.AutoCompleteModule,
                inputswitch_1.InputSwitchModule, scrollpanel_1.ScrollPanelModule, inputtextarea_1.InputTextareaModule, checkbox_1.CheckboxModule, colorpicker_1.ColorPickerModule,
                togglebutton_1.ToggleButtonModule
            ],
            declarations: [
                app_component_1.AppComponent,
                components_1.DashboardComponent,
                components_1.HomeComponent,
                directives_1.InputValidationComponent,
                directives_1.LoadingComponent,
                directives_1.NavbarComponent,
                directives_1.NotificationsComponent,
                directives_1.MessagesComponent,
                components_1.LoginComponent,
                components_1.AdvanceSearchComponent,
                components_1.AlertComponent,
                components_1.UserManagementComponent,
                components_1.CompanyManagementComponent,
                components_1.RegionManagementComponent,
                directives_1.TransactionAdvanceSearchComponent,
                directives_1.TransactionAlertComponent,
                directives_1.TransactionMapComponent,
                directives_1.BingMapComponent,
                directives_1.BingMapsComponent,
                directives_1.ReportDetailComponent,
                directives_1.ReportDetailExpandableComponent,
                pipes_1.CeilPipe
            ],
            providers: [
                _1.AuthGuard,
                services_1.ServerService,
                services_1.MapReportService,
                services_1.CompanyService,
                services_1.RegionService,
                services_1.UserService,
                services_1.AdvanceSearchService,
                services_1.NotificationsService,
                messageservice_1.MessageService,
                resolvers_1.TransactionProductResolve,
                resolvers_1.CompanyManagementResolve,
                resolvers_1.RegionManagementResolve,
                { provide: common_1.APP_BASE_HREF, useValue: "/" },
                { provide: http_1.HTTP_INTERCEPTORS, useClass: helpers_1.RequestInterceptor, multi: true },
                { provide: http_1.HTTP_INTERCEPTORS, useClass: helpers_1.ErrorInterceptor, multi: true }
            ],
            bootstrap: [app_component_1.AppComponent]
        })
    ], AppModule);
    return AppModule;
}());
exports.AppModule = AppModule;
