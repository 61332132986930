"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../../services");
var base_bing_map_component_1 = require("./base.bing-map.component");
var BingMapComponent = /** @class */ (function (_super) {
    __extends(BingMapComponent, _super);
    function BingMapComponent(mapReportService, notificationsService) {
        return _super.call(this, mapReportService, notificationsService) || this;
    }
    Object.defineProperty(BingMapComponent.prototype, "data", {
        set: function (value) {
            value.visible = true;
            this.mapReports = [value];
            if (this.map != undefined) {
                this.setMapReports();
            }
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [Object])
    ], BingMapComponent.prototype, "data", null);
    BingMapComponent = __decorate([
        core_1.Component({
            selector: "bing-map",
            template: require('./bing-map.component.html')
        }),
        __metadata("design:paramtypes", [services_1.MapReportService,
            services_1.NotificationsService])
    ], BingMapComponent);
    return BingMapComponent;
}(base_bing_map_component_1.BaseBingMapComponent));
exports.BingMapComponent = BingMapComponent;
