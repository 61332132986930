"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../../services");
var base_bing_map_component_1 = require("./base.bing-map.component");
var BingMapsComponent = /** @class */ (function (_super) {
    __extends(BingMapsComponent, _super);
    function BingMapsComponent(mapReportService, notificationsService) {
        return _super.call(this, mapReportService, notificationsService) || this;
    }
    Object.defineProperty(BingMapsComponent.prototype, "mapId", {
        set: function (value) {
            this.bingMapId = "bingMaps" + value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BingMapsComponent.prototype, "data", {
        set: function (value) {
            this.mapReports = value.map(function (v) {
                v.visible = true;
                return v;
            });
        },
        enumerable: true,
        configurable: true
    });
    BingMapsComponent.prototype.displaySwitch = function (index) {
        if (this.mapReports[index].visible) {
            this.mapReports[index].heatMap.show();
            this.map.layers.insert(this.mapReports[index].layer);
        }
        else {
            this.mapReports[index].heatMap.hide();
            this.map.layers.remove(this.mapReports[index].layer);
        }
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", String),
        __metadata("design:paramtypes", [String])
    ], BingMapsComponent.prototype, "mapId", null);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [Array])
    ], BingMapsComponent.prototype, "data", null);
    BingMapsComponent = __decorate([
        core_1.Component({
            selector: "bing-maps",
            template: require('./bing-maps.component.html')
        }),
        __metadata("design:paramtypes", [services_1.MapReportService,
            services_1.NotificationsService])
    ], BingMapsComponent);
    return BingMapsComponent;
}(base_bing_map_component_1.BaseBingMapComponent));
exports.BingMapsComponent = BingMapsComponent;
