"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BasePrimeNgGridComponent = /** @class */ (function () {
    function BasePrimeNgGridComponent(notificationsService, route) {
        this.notificationsService = notificationsService;
        this.route = route;
        this.loading = true;
        this.totalRecords = 0;
        this.gridLayoutInfo = this.route.snapshot.data["gridLayoutInfo"];
    }
    BasePrimeNgGridComponent.prototype.refresh = function () {
        this.refreshing = true;
        this.lazyLoadGridItems(this.lazyLoadEvent);
    };
    BasePrimeNgGridComponent.prototype.filter = function () {
        return { filter: this.lazyLoadEvent, cols: this.cols };
    };
    BasePrimeNgGridComponent.prototype.lazyLoadGridItems = function (event) {
        this.loading = true;
        this.lazyLoadEvent = event;
        this.lazyLoad(event);
    };
    BasePrimeNgGridComponent.prototype.colNgStyle = function (col) {
        return { 'display': (col.hidden || false ? "none" : "table-cell") };
    };
    BasePrimeNgGridComponent.prototype.columnsLength = function () {
        return this.cols.filter(function (item) { return !item.hidden; }).length + 1;
    };
    BasePrimeNgGridComponent.prototype.lazyLoad = function (event) { };
    BasePrimeNgGridComponent.prototype.expandedColumnSpanLength = function () {
        return this.cols.filter(function (c) { return !c.hidden; }).length + 1;
    };
    return BasePrimeNgGridComponent;
}());
exports.BasePrimeNgGridComponent = BasePrimeNgGridComponent;
