"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var shared_1 = require("../shared");
var server_service_1 = require("./server.service");
var MapReportService = /** @class */ (function () {
    function MapReportService(serverService) {
        this.serverService = serverService;
        this.safeCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-";
    }
    MapReportService.prototype.getMapReportByAdvanceSearchId = function (advanceSearchId) {
        return this.serverService.get(shared_1.Global.mapReportEndpoint + "/" + advanceSearchId, "");
    };
    MapReportService.prototype.getDashboardMap = function () {
        return this.serverService.get(shared_1.Global.mapReportEndpoint + "/dashboard", "");
    };
    MapReportService.prototype.reloadMapReport = function (reportMap) {
        return this.serverService.post(shared_1.Global.mapReportEndpoint + "/reload", reportMap);
    };
    MapReportService.prototype.createOrUpdate = function (reportMap) {
        return this.serverService.post("" + shared_1.Global.mapReportEndpoint, reportMap);
    };
    MapReportService.prototype.getDecodedBoundary = function (decoded) {
        var _this = this;
        var polygonArray = new Array();
        decoded.split(",").forEach(function (value) {
            var index = 0;
            var xSum = 0;
            var ySum = 0;
            var max = 4294967296;
            var array = new Array();
            while (index < value.length) {
                var n = 0;
                var k = 0;
                while (1) {
                    if (index >= value.length) {
                        return null;
                    }
                    var b = _this.safeCharacters.indexOf(value.charAt(index++));
                    if (b === -1) {
                        return null;
                    }
                    var tmp = ((b & 31) * (Math.pow(2, k)));
                    var ht = tmp / max;
                    var lt = tmp % max;
                    var hn = n / max;
                    var ln = n % max;
                    var nl = (lt | ln) >>> 0;
                    n = (ht | hn) * max + nl;
                    k += 5;
                    if (b < 32)
                        break;
                }
                var diagonal = parseInt(((Math.sqrt(8 * n + 5) - 1) / 2).toString());
                n -= diagonal * (diagonal + 1) / 2;
                var ny = parseInt(n.toString());
                var nx = diagonal - ny;
                nx = (nx >> 1) ^ -(nx & 1);
                ny = (ny >> 1) ^ -(ny & 1);
                xSum += nx;
                ySum += ny;
                var lat = ySum * 0.00001;
                var lon = xSum * 0.00001;
                array.push(new Microsoft.Maps.Location(lat, lon));
            }
            polygonArray.push(array);
            return null;
        });
        return polygonArray;
    };
    MapReportService.prototype.opacityLevel = function (containerQuantity, pctAvgDiff) {
        var level = Math.ceil(Math.abs(parseFloat(pctAvgDiff) / 5 + containerQuantity / 10) / 50 * 255);
        if (level > 240)
            return 240;
        else if (level < 17)
            return 17;
        return level;
    };
    MapReportService.prototype.setPolygon = function (report, data) {
        var _this = this;
        var opacityLevel = this.opacityLevel(data.containerQuantity, data.pctAvgDiff);
        var polygon = new Microsoft.Maps.Polygon(this.getDecodedBoundary(data.boundary), {
            fillColor: data.overlayColor + opacityLevel.toString(16),
            strokeColor: data.strokeColor,
            strokeThickness: 2,
        });
        polygon.metadata = {
            title: "" + report.advanceSearchName,
            description: "Zip code: " + data.zipCode + " \n                        <br/> Total Population: " + this.numberFormat(data.populationTotal) + "\n                        <br/> Quantity: " + this.numberFormat(data.containerQuantity) + " of " + this.numberFormat(data.locationContainerQuantity) + " (" + data.pctLocationTotal + ") \n                        <br/> Avg. Diff.: " + data.pctAvgDiff,
        };
        //Add an click event handler to the IPrimitive.
        Microsoft.Maps.Events.addHandler(polygon, "mouseover", function (e) { return _this.mouseOver(report.infoBox, e); });
        Microsoft.Maps.Events.addHandler(polygon, "mouseout", function (e) { return _this.mouseOut(report.infoBox, e); });
        report.layer.add(polygon);
        var polygonLocation = new Microsoft.Maps.Location(data.latitude, data.longitude);
        Microsoft.Maps.loadModule("Microsoft.Maps.Clustering", function () {
            data.pushPins.forEach(function (p) {
                var location = new Microsoft.Maps.Location(p.latitude, p.longitude);
                var pin = new Microsoft.Maps.Pushpin(location, {
                    color: data.strokeColor + opacityLevel.toString(16)
                });
                pin.metadata = {
                    title: "" + report.advanceSearchName,
                    description: _this.getPushPinDescription(p),
                };
                Microsoft.Maps.Events.addHandler(pin, "mouseover", function (e) { return _this.mouseOver(report.infoBox, e); });
                Microsoft.Maps.Events.addHandler(pin, "mouseout", function (e) { return _this.mouseOut(report.infoBox, e); });
                report.layer.add(pin);
                var density = Math.floor(opacityLevel / 34 * _this.getPushPinContainerQuantity(p) / 100.0);
                if (density < 2)
                    return;
                density = Math.min(density + 5, 10);
                for (var i = 0; i < density; i++) {
                    report.locations.push(location);
                }
            });
        });
        return polygonLocation;
    };
    MapReportService.prototype.mouseOver = function (infoBox, e) {
        //Make sure the info box has metadata to display.
        if (e.target.metadata) {
            //Set the info box options with the metadata of the pushpin.
            var infoBoxTemplate = '<div class="customInfoBox"><div class="title">{title}</div>{description}</div>';
            infoBox.setOptions({
                //Use the location of where the mouse was clicked to position the info box.
                location: e.location,
                htmlContent: infoBoxTemplate.replace("{title}", e.target.metadata.title)
                    .replace("{description}", e.target.metadata.description),
                visible: true
            });
        }
    };
    MapReportService.prototype.mouseOut = function (infoBox, e) {
        //Make sure the info box has metadata to display.
        if (e.target.metadata) {
            //Set the info box options with the metadata of the pushpin.
            infoBox.setOptions({
                visible: false
            });
        }
    };
    MapReportService.prototype.getPushPinContainerQuantity = function (p) {
        return p.pharmacies.map(function (f) { return f.containerQuantity; }).reduce(function (a, b) { return a + b; });
    };
    MapReportService.prototype.getPushPinDescription = function (p) {
        var _this = this;
        return p.pharmacies
            .map(function (f) { return "<hr/><b>" + f.name + "<b/> \n                        <br/> Address: " + f.address + " \n                        <br/> Quantity: " + _this.numberFormat(f.containerQuantity) + " of " + _this.numberFormat(f.locationContainerQuantity) + " (" + f.pctLocationTotal + ") \n                        <br/> Zip Code Total: " + f.pctZipCodeContainerQuantity; })
            .join("");
    };
    /**
     * Number.prototype.format(n, x, s, c)
     *
     * @param integer n: length of decimal
     * @param integer x: length of whole part
     * @param mixed   s: sections delimiter
     * @param mixed   c: decimal delimiter
     */
    MapReportService.prototype.format = function (v, n, x, s, c) {
        var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")";
        var num = v.toFixed(Math.max(0, ~~n));
        return (c ? num.replace(".", c) : num).replace(new RegExp(re, "g"), "$&" + (s || ","));
    };
    MapReportService.prototype.numberFormat = function (v, n) {
        return this.format(v, n === null ? 0 : n, 3, ",", ".");
    };
    MapReportService = __decorate([
        core_1.Injectable(),
        __metadata("design:paramtypes", [server_service_1.ServerService])
    ], MapReportService);
    return MapReportService;
}());
exports.MapReportService = MapReportService;
